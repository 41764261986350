import styled from "@emotion/styled";
import { MACBOOK_PRO_MAX_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MID_HEADER_DESKTOP_MENU, MOBILE_QUERY_MIN_WIDTH, XS } from "constants/vp-sizes";
const LOGO_BIG_SCREEN_WIDTH = 150;
const LOGO_SMALL_SCREEN_WIDTH = 120;
const LOGO_SLIM_WIDTH = 72;
const LOGO_SLIM_HEIGHT = 35;
const LOGO_WIDTH_TOTAL_PADDING = 32;
const LOGO_BIG_SCREEN_HEIGHT = 69;
const LOGO_SMALL_SCREEN_HEIGHT = 50;
export const StyledLogos = styled.div`
    margin: auto;
    display: flex;
    justify-content: center;

    .image-gallery {
      transform: scale(${(props)=>props.logosScale});
    }

    .images-logos-wrapper {
      width: 100%;
    }

    .images-gallery-row {
      display: flex;
      justify-content: center;

      flex-wrap: ${(props)=>props.useSingleLine ? "nowrap" : "wrap"};
      padding-inline: ${(props)=>props.paddingInlined};

      .image-gallery {
        padding: 24px 0;
        display: flex;
        justify-content: center;
        width: 244px;

        .social-proof-gallery-picture {
          img {
            max-height: ${LOGO_BIG_SCREEN_HEIGHT}px;
            max-width: ${LOGO_BIG_SCREEN_WIDTH - LOGO_WIDTH_TOTAL_PADDING}px;
          }
        }
      }
    }

    &.slim.slim {
      .images-gallery-row {
        justify-content: flex-start;
        gap: 32px;
        .image-gallery {
          padding: 8px 0;
          width: auto;

          .social-proof-gallery-picture {
            img {
              max-height: ${LOGO_SLIM_HEIGHT}px;
              max-width: ${LOGO_SLIM_WIDTH}px;
            }
          }
        }
      }
    }

    &.new-layout {
      @media (max-width: ${MID_HEADER_DESKTOP_MENU}px) {
        max-width: ${XS}px;
      }

      @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) and (min-width: ${MID_HEADER_DESKTOP_MENU}px) {
        .images-logos-wrapper {
          .images-gallery {
            .images-gallery-row {
              .image-gallery {
                max-width: 120px;

                .social-proof-gallery-picture {
                  img {
                    max-height: 56px;
                    max-width: 96px;
                  }
                }
              }
            }
          }
        }
      }

      .images-gallery-row {
        .image-gallery {
          width: ${LOGO_BIG_SCREEN_WIDTH}px;
        }
      }
    }
  

  @media (max-width: ${MACBOOK_PRO_MAX_WIDTH}) {
   
      &:not(.new-layout) {
        .images-gallery-row {
          .image-gallery {
            padding: 12px 0;
            margin: 0 20px;
            width: 170px;
          }
        }
      }
      &.slim.slim {
        .images-gallery-row {
          gap: 24px;
        }
      }
    
  }

  @media (max-width: ${MOBILE_QUERY_MIN_WIDTH}) {
   :not(.new-layout),
    .new-layout {
      margin-top: 16px;
      .images-gallery-row {
        padding: 0;

        .image-gallery {
          width: ${LOGO_SMALL_SCREEN_WIDTH}px;
          margin: 0;
          padding: 0 0 25px 0;

          .social-proof-gallery-picture {
            img {
              max-height: ${LOGO_SMALL_SCREEN_HEIGHT}px;
              max-width: ${LOGO_SMALL_SCREEN_WIDTH - LOGO_WIDTH_TOTAL_PADDING}px;
            }
          }
        }
      }
    
    .slim.slim {
      .images-logos-wrapper {
        max-width: 300px;
        .images-gallery-row {
          .image-gallery {
            margin: 0;
            width: unset;
            .social-proof-gallery-picture {
              img {
                max-height: 48px;
                max-width: 92px;
              }
            }
          }
        }
      }
  }
`;
